<template>
        <div class="news-page" key="content">
            <transition name="page">
                <div class="news-content" v-if="!isLoading" key="content">
                    <div class="container">
                        <div class="head-box">
                            <w-breadcrumb :items="breadcrumb"></w-breadcrumb>

                            <div class="tag" @click="goToNewsBlock(news.lastTag.title)">
                                {{ '#' + news.lastTag.title }}
                            </div>
                        </div>

                        <div class="info-box">
                            <div class="sub-info">
                                <div class="date">
                                    {{ date }}
                                </div>

                                <div class="tag" :style="{color: news.lastTag.color}">
                                    {{ '#' + news.lastTag.title }}
                                </div>
                            </div>

                            <h1 class="title" v-html="news.title"></h1>
                        </div>
                    </div>

                    <div class="poster">
                        <img :src="news.posterBig" :alt="news.title" width="100%" height="100%">
                        <div class="description" v-html="news.description"></div>
                    </div>

                    <div class="container">
                        <div class="content" v-if="text">
                            <template v-for="(block, key) in text">

                                <h2 :key="key" v-if="block.type === 'header' && block.data.level === 2" v-html="block.data.text"></h2>
                                <h3 :key="key" v-else-if="block.type === 'header' && block.data.level === 3" v-html="block.data.text"></h3>
                                <h4 :key="key" v-else-if="block.type === 'header' && block.data.level === 4" v-html="block.data.text"></h4>
                                <h5 :key="key" v-else-if="block.type === 'header' && block.data.level === 5" v-html="block.data.text"></h5>

                                <p :key="key" v-else-if="block.type ==='paragraph'" v-html="block.data.text"></p>

                                <ol :key="key" v-else-if="block.type === 'list' && block.data.style === 'ordered'">
                                    <template v-for="(item, key) in block.data.items">
                                        <li :key='key' v-html="item.content"></li>
                                        <ul v-if="item.items.length > 0" :key='key + 0.1'>
                                            <li v-for="(innerItem, index) in item.items" v-html="innerItem.content" :key="index"></li>
                                        </ul>
                                    </template>
                                </ol>

                                <ul :key="key" v-else-if="block.type === 'list' && block.data.style === 'unordered'">
                                    <template v-for="(item, key) in block.data.items">
                                        <li :key='key' v-html="item.content"></li>
                                        <ul v-if="item.items.length > 0" :key='key + 0.1'>
                                            <li v-for="(innerItem, index) in item.items" v-html="innerItem.content" :key="index"></li>
                                        </ul>
                                    </template>
                                </ul>

                                <table :key="key" v-else-if="block.type === 'table'">
                                    <thead v-if="block.data.withHeadings">
                                        <tr>
                                            <template v-for="(column, key) in block.data.content[0]" >
                                                <th :key="key" v-html="column"></th>
                                            </template>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <template v-if="block.data.withHeadings">
                                            <template v-for='(row, key) in block.data.content.splice(1) '>
                                                <tr :key='key'>
                                                    <template v-for="(column, key) in row" >
                                                        <td :key="key" v-html="column"></td>
                                                    </template>
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-else v-for='(row, key) in block.data.content '>
                                            <tr :key='key'>
                                                <template v-for="(column, key) in row" >
                                                    <td :key="key" v-html="column"></td>
                                                </template>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>

                                <hr :key="key" v-else-if="block.type === 'delimiter'">

                                <figure class="quote" :key="key" v-else-if="block.type === 'quote'">
                                    <blockquote v-html="block.data.text"></blockquote>

                                    <figcaption v-if="block.data.caption.includes(',')">
                                        <span class="quote-author-name" v-html="`—${block.data.caption.split(',')[0]}`"></span>

                                        <span class="quote-author-position" v-html="`—${block.data.caption.split(',')[1]}`"></span>
                                    </figcaption>

                                    <figcaption v-else>
                                        <span class="quote-author-name" v-html="`—${block.data.caption}`"></span>
                                    </figcaption>
                                </figure>

                                <code :key="key" v-else-if="block.type === 'code'" v-html="block.data.code"></code>

                                <figure :key="key" v-else-if="block.type === 'embed'">
                                    <iframe type="text/html" :width="block.data.width" :height="block.data.height" :src="block.data.embed"></iframe>

                                    <figcaption v-if="block.data.caption && block.data.caption !== '<br>'" v-html="block.data.caption"></figcaption>
                                </figure>

                                <figure :key="key" v-else-if="block.type === 'image' && block.data.caption && block.data.caption !== '<br>'" class="image">
                                    <img :src="block.data.file.url" width='100%' height='100%' :alt="block.data.caption.replace('<br>', '')">

                                    <figcaption>
                                        <div class="image-caption" v-html="block.data.caption"></div>
                                    </figcaption>
                                </figure>

                                <figure :key="key" v-else-if="block.type === 'image'">
                                    <img alt="image" :src="block.data.file.url" width="100%" height="100%">
                                </figure>

                                <aside :key="key" v-else-if="block.type === 'warning'" v-html="`${block.data.title}${block.data.title.length ? '<br>' : ''}${block.data.message}`" ></aside>

                                <div :key="key" v-else></div>
                            </template>
                        </div>

                        <div class="content" v-else v-html="this.news.text"></div>

                        <div class="bottom">
                            <div class="tags">
                                <div v-for="(tag, index) in news.tags" class="tag" @click="goToNewsBlock(tag.title)" :key="index">
                                    {{ '#' + tag.title }}
                                </div>
                            </div>

                            <w-share-tooltip :title="$i18n.get(`Share`)"></w-share-tooltip>
                        </div>

                        <div v-if="news.author" class="author">
                            <div class="author__picture">
                                <img :src="news.author.photo" :alt="news.author.name" class="author__picture-img">
                            </div>

                            <div class="author__data">
                                <div class="author__data-name">
                                    {{ news.author.name }}
                                </div>

                                <div class="author__data-position">
                                    {{ news.author.position }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <news-preloader v-else key="preloader"></news-preloader>
            </transition>

            <div class="latest-news">
                <div class="container">
                    <div class="head">
                        <div class="title">
                            {{ $i18n.get(`Other news`) }}
                        </div>

                        <w-button type="white-border" :to="{name: 'community'}" size='xs'>
                            {{ $i18n.get(`See all`) }}
                        </w-button>
                    </div>

                    <div v-if="!isLoading" class="content">
                        <news-item  v-for="(news, index) of latestNews" :key="index" :news="news"/>
                    </div>

                    <w-loader color="white" v-else></w-loader>
                </div>

                <w-button type="white-border" :to="{name: 'community'}" size="xs" block>
                    {{ $i18n.get(`See all`) }}
                </w-button>
            </div>
        </div>
</template>

<script>
import NewsItem from '../community/snippets/NewsItem.vue'
import NewsPreloader from './snippets/NewsPreloader.vue'

export default {
    name: 'news-page',
    props: {
        slug: {
            type: String,
            default: ''
        }
    },
    components: {
        'news-item': NewsItem,
        'news-preloader':NewsPreloader,
    },
    data() {
        return {
            isLoading: true,
            news: {},
            latestNews: [],
        }
    },
    methods: {
        goToNewsBlock(tags) {
            this.$router.push({name: 'news', query: {predefinedTags: tags}})
        }
     },
    computed: {
        breadcrumb() {
            return [
                {
                    title: 'Community',
                    link: '/community'
                },
                {
                    title: 'News',
                    link: '/community/news'
                },
                {
                    title: this.news.title,
                }
            ]
        },
        date() {
            return this.$moment(this.news.date).format('MMMM D, YYYY')
        },
        text() {
            return JSON.parse(this.news.textEditor)
        }
    },
    created() {
        this.isLoading = true

        this.$request.get('/community/news/' + this.slug, {}, response => {
            this.isLoading = false
            this.news = response.news
            this.latestNews = response.latestNews

            this.$meta.set({
                title: this.news.title,
                image: this.news.posterSmall,
                description: this.news.description
            })
        }, () => {
            return this.$router.push('/404')
        })

    },
    mounted() {
        window.scrollTo(0, 0)
    },
    beforeDestroy() {
        this.$meta.clear()
    }
}
</script>

<style lang="less">
.news-page {
    font-family: 'Poppins';
    background-color: var(--white);

    .news-content {

        .container {
            max-width: var(--main-width);
            margin: 0 auto;
            
            > .head-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 54px;
    
                > .tag {
                    padding: 8px 16px;
                    border: 1px solid var(--dark-blue-20);
                    border-radius: 100px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: var(--dark-blue);
                }
            }
    
            > .info-box {
                margin-top: 27px;
                margin-bottom: 18px;

                > h1 {
                  margin: 0;
                }

                > .sub-info {
    
                    > .date {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 180%;
                        color: var(--dark-blue-40);
                    }

                    > .tag {
                        display: none;
                    }
                }
                
                > .title {
                    color: var(--dark-blue);
                    font-weight: 700;
                    font-size: 46px;
                    line-height: 144%;
                    margin: 0;
                }
            }

            &:last-child {
                margin-bottom: 85px;
            }
        }
    
        > .poster {
            margin: 0 auto;
            max-width: 946px;
            position: relative;

            img {
                width: 100%;
                object-fit: contain;
                border-radius: 30px;

            }

            > .description {
                position: absolute;
                left: 50px;
                bottom: 50px;
                padding: 16px 29px;
                background-color: var(--dark-blue-60);
                backdrop-filter: blur(10px);
                color: var(--white);
                border-radius: 20px;
                max-width: 408px;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
            }
        }
    
        > .container {
            > .content {
                margin-top: 41px;
                font-weight: 400;
                font-size: 16px;
                line-height: 29px;
                color: var(--dark-blue);

                b {
                    font-weight: bolder;
                }

                > h1 {
                  font-size: 46px;
                }

                > h2 {
                  font-size: 36px;
                }

                > h3 {
                  font-size: 32px;
                }

                > h4 {
                  font-size: 28px;
                }

                > h5 {
                  font-size: 24px;
                }

                a {
                  position: relative;
                  &:hover {
                    &::before {
                      content: '';
                      position: absolute;
                      left: 0;
                      bottom: 3px;
                      width: 100%;
                      height: 1px;
                      background-color: var(--blue-link-hover);
                      opacity: .2;
                    }
                  }
                }

                > br {
                    content: ' ';
                    display: block;
                    margin: 15px 0;
                }

                hr {
                  clear: both;
                  position: relative;
                  color: #CCD1DD;
                  margin: 45px 0;
                  &::before {
                    content: '';
                    width: 40px;
                    height: 3px;
                    position: absolute;
                    left: calc(50% - 20px);
                    top: -1px;
                    background-color: #fff;
                  }
                  &::after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    left: calc(50% - 5px);
                    top: calc(50% - 5px);
                    background-color: #CCD1DD;
                    transform: rotate(45deg);
                  }
                }

                p, h1, h2, h3, h4, h5 {
                    margin: 18px 0;
                }

                h1, h2, h3, h4, h5 {
                    line-height: 1.2;
                }

                ul {
                    list-style: none;
                    li {
                        position: relative;
                        &::before {
                            content: '';
                            position: absolute;
                            left: -15px;
                            top: 10px;
                            width: 8px;
                            height: 8px;
                            background-color: var(--green);
                            border-radius: 50%;
                        }
                    }
                }

                ol {
                    li {
                        &::marker {
                            font-weight: bold;
                        }
                    }
                }

                h1, h2, h3, h4, h5 {
                    line-height: 1.2;
                }

                ul, ol {
                    margin: 20px 0 20px 15px;
                    li {
                        padding-left: 10px;
                        margin: 10px 0;
                    }
                    > ul {
                        margin: 10px 0 10px 15px;
                        li {
                            &::before {
                                content: '';
                                position: absolute;
                                left: -15px;
                                top: 10px;
                                width: 7px;
                                height: 7px;
                                background: var(--dark-blue-80);
                                border-radius: 50%;
                            }
                        }
                    }
                }

                figure {
                  margin: 25px 0;
                }

                .quote {
                    position: relative;
                    padding: 30px 0 10px 42px;
                    &::before {
                        content: '';
                        width: 3px;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: var(--blue-link);
                        border-radius: 20px;
                    }
                    &::after {
                        content: '';
                        width: 74px;
                        height: 56px;
                        position: absolute;
                        left: 29px;
                        top: 5px;
                        background-image: url(./images/quote.svg);
                    }
                    blockquote {
                        font-style: italic;
                        margin-bottom: 20px;
                        &.text-center {
                            text-align: center;
                        }
                    }
                    &-author {
                        &-name {
                            font-weight: 600;
                        }
                        &-position {
                            font-weight: 400;
                            color: var(--dark-blue-40);
                            margin-left: 5px;
                        }
                    }
                }

                aside {
                    color: #fff;
                    background: var(--dark-blue);
                    border-radius: 20px;
                    padding: 42px 48px;

                    ul, ol {
                        margin-left: 40px;
                        margin-bottom: 0;
                    }
                }

                table {
                    width: 100%;
                    text-align: center;
                    line-height: 18px;
                    margin-bottom: 20px;
                    border-collapse: collapse;
                    border-radius: 15px;
                    overflow: hidden;

                    thead {
                        background: #D9E0E9;
                        tr {
                            th {
                                font-weight: 500;
                                padding: 15px;
                                &:first-child {
                                    text-align: left;
                                    padding-left: 25px;
                                }
                                &:last-child {
                                    padding-right: 25px;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            background: var(--blue-gray);
                            &:nth-of-type(2n+1) {
                                background: #fdfdfe;
                            }
                            td {
                                padding: 15px;
                                &:first-child {
                                    text-align: left;
                                    padding-left: 25px;
                                }
                                &:last-child {
                                    padding-right: 25px;
                                }
                            }
                        }
                    }
                }

                img {
                    max-width: 100%;
                    object-fit: contain;
                    border-radius: 15px;
                }

                .image {
                    display: flex;
                    img {
                        width: 580px;
                    }
                    figcaption {
                        position: relative;
                        padding: 35px 0 0 35px;
                    }
                    &-caption {
                        position: relative;
                        color: var(--dark-blue-60);
                        line-height: 24px;
                        padding-bottom: 15px;
                        &::before {
                            content: '';
                            width: 40px;
                            height: 2px;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            background: var(--blue-link);
                        }
                    }
                }

                iframe {
                    max-width: 100%;
                }
            }
    
            > .bottom {
                font-weight: 600;
                font-size: 14px;
                line-height: 180%;
                margin-top: 40px;
                margin-bottom: 30px;
                padding-top: 27px;
                border-top: 1px solid var(--dark-blue-10);
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 21px;
                > .tags {
                    display: flex;
                    > .tag {
                        color: var(--blue-link);
                        font-weight: 500;
                        margin: 0 7.5px;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                > .w-share-form-group {
                    span {
                        border-bottom: 1px dashed;
                        line-height: 15px;
                    }
                }
            }

            .author {
                display: flex;
                align-items: center;
                &__picture {
                    width: 68px;
                    height: 68px;
                    border-radius: 50%;
                    margin-right: 15px;
                    &-img {
                        max-width: 100%;
                    }
                }
                &__data {
                    &-name {
                        font-size: 27px;
                        font-weight: 700;
                        color: var(--dark-blue);
                    }
                    &-position {
                        font-size: 16px;
                        color: var(--dark-blue-40);
                    }
                }
            }
        }

        .tag {
            &:hover {
                cursor: pointer;
            }
        }
    }

    > .latest-news {
        height: 510px;
        background-image: url(./images/news-page-latest-news-bg.webp);
        background-size: 2560px 510px;
        background-position:  center;
        padding: 70px 15px 76px 15px;

        > .container {
            max-width: 1180px;
            margin: 0 auto;
            
            > .head {
                display: flex;
                justify-content: space-between;
                align-items: center;

                > .title {
                    color: var(--white);
                    font-weight: bold;
                    font-size: 36px;
                    line-height: 144%;
                }

                > .btn {
                    font-weight: 400;
                    border: none;
                    background: var(--white-10);

                    &:hover {
                        background: var(--white-20);
                    }
                }
            }

            > .content {
                display: flex;
                column-gap: 18px;
                margin-top: 18px;
            }
        }

        > .btn {
            display: none;
        }
    }
}

@media (max-width: 1023px) {
    .news-page {
        .news-content {
            .container {
                > .head-box  {
                    padding-top: 20px;
                }

                > .info-box {

                    > .title {
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 144%;
                    }
                }
            }
        
            > .poster {
                margin: 0 auto;
                max-width: 768px;

                img {
                    object-fit: contain;
                }
            }
        
            > .container {
                > .content {
                    margin-top: 41px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 25px;
                    .image {
                        flex-direction: column;
                        img {
                            width: 100%;
                        }
                        figcaption {
                            position: relative;
                            padding: 15px 0 0;
                        }
                        &-caption {
                            padding: 0 0 0 15px;
                            &::before {
                                width: 2px;
                                height: 100%;
                                left: 0;
                                top: 0;
                            }
                        }
                    }
                }
        
                > .share {
                    margin-top: 30px;
                    padding-top: 20px;
                    margin-bottom: 50px;
                }
            }
        }

        > .latest-news {
            height: 450px;
            background-image: url(./images/news-page-latest-news-bg.webp);
            background-size: 2560px 510px;
            background-position:  center;
            padding: 40px 15px 76px 15px;

            > .container {
                max-width: var(--main-width);
                margin: 0 auto;

                > .head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    > .title {
                        color: var(--white);
                        font-weight: bold;
                        font-size: 36px;
                        line-height: 144%;
                    }

                    > .btn {
                        font-weight: 400;
                        border: none;
                        background: var(--white-10);

                        &:hover {
                            background: var(--white-20);
                        }
                    }
                }

                > .content {
                    display: flex;
                    column-gap: 18px;
                    margin-top: 18px;

                    > div:last-child {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .news-page {
        font-family: 'Poppins';

        .news-content {
            .container {
                max-width: 100%;
                margin: 0 auto;

                > .content {

                    > h1 {
                        font-size: 24px;
                    }

                    > h2 {
                        font-size: 18px;
                    }

                    > h3 {
                        font-size: 16px;
                    }

                    > h4 {
                        font-size: 14px;
                    }

                    > h5 {
                        font-size: 12px;
                    }
                }

                > .head-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0;
                    background-color: var(--blue-gray);
        
                    > .tag {
                        display: none;
                    }
                }
        
                > .info-box {
                    padding: 13px 15px 0px 15px;
                    margin-top: 0px;
                    margin-bottom: 18px;

                    > .sub-info {
                        display: flex;
                        column-gap: 14px;

                        > .date {
                            font-weight: 500;
                            font-size: 11px;
                            line-height: 180%;
                        }

                        > .tag {
                            display: block;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            position: relative;
                        }

                        > .tag:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 7px;
                            left: -7px;
                            width: 3px;
                            height: 3px;
                            border-radius: 50%;
                            background-color: var(--dark-blue-40);
                        }
                    }
                    
                    > .title {
                        color: var(--dark-blue);
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 144%;
                    }
                }

                > .bottom {
                    margin-top: 0;
                    padding: 27px 15px 0;
                }

                > .author {
                    padding: 0 15px;
                    .author__data {
                        &-name {
                            font-size: 22px;
                        }
                        &-position {
                            font-size: 14px;
                        }
                    }
                }
            }
        
            > .poster {
                margin: 0 auto -4px auto;
                max-width: 768px;
                border-radius: 0;
                position: relative;

                img {
                    border-radius: 0;
                }

                > .description {
                    left: 15px;
                    bottom: 19px;
                    padding: 10px 14px;
                    border-radius: 10px;
                    max-width: 235px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        
            > .container {
                > .content {
                    padding: 15px 15px;
                    margin: 0;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;

                    .table {
                        overflow-x: auto;
                    }
                }
        
                > .share {
                    margin: 0;
                    padding: 15px 0px 30px 0px;
                    margin: 0px 15px;
                    font-size: 12px;
                    line-height: 180%;
                    border-top: 1px solid var(--dark-blue-10);
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                }
            }
        }

        > .latest-news {
            height: auto;
            background-image: url(./images/news-page-latest-news-bg.webp);
            background-size: 2560px 1100px;
            background-position: center;
            padding: 15px 15px 34px 15px;

            > .container {
                max-width: var(--main-width);
                margin: 0 auto;
                
                > .head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    > .title {
                        font-size: 24px;
                        line-height: 144%;
                    }

                    > .btn {
                        display: none;
                    }
                }

                > .content {
                    row-gap: 15px;
                    margin-top: 13px;
                    flex-wrap: wrap;
                }
            }

            > .btn {
                max-width: var(--main-width);
                margin: 0 auto;
                display: block;
                background: var(--white-10);
                border: none;
                margin-top: 15px;
                text-align: center;

                &:hover {
                    border: none;
                    background: var(--white-20);
                }
            }
        }
    }
}

@media (min-width:1280px) {
    .news-page {
        --main-width: 800px;
    }
}

.page-enter-active, .page-leave-active{
    transition: all 0.4s;
}

.page-enter, .page-leave-to {
    // position: absolute;
    opacity: 0;
}
</style>
