<template>
    <div class="news-content -preloader">
        <div class="container">
            <div class="head-box">
                <w-breadcrumb :items="breadcrumb"></w-breadcrumb>

                <div class="tag">
                    titletitletitle
                </div>
            </div>

            <div class="info-box">
                <div class="sub-info">
                    <div class="date">
                        datedatedate
                    </div>

                    <div class="tag">
                        news tag title
                    </div>
                </div>

                <div class="title"></div>
            </div>
        </div>

        <div class="poster">
            <img :src="require('../images/preloader-image.webp')">
            <div class="description">news.description</div>
        </div>

        <div class="container -empty">
        </div>
    </div>
</template>

<script>
export default {
    name: 'news-preloader',
    computed: {
        breadcrumb() {
            return [
                {
                    title: 'Community',
                    link: '/community'
                },
                {
                    title: 'Loading...',
                }
            ]
        },
    }
}
</script>


<style lang="less" scoped>
.-preloader {
    .tag {
        background-color: var(--dark-blue-20);
        color: transparent !important;
        user-select: none;
        border: none !important;
        border-radius: 100px;
    }
    .container {
        &.-empty {
            min-height: 1000px;
        }
    }

    .sub-info {
        display: inline-block;
        border-radius: 100px;

        > .date {
            background-color: var(--dark-blue-20);
            color: transparent !important;
            border-radius: 100px;
        }
    }

    .title {
        background-color: var(--dark-blue-20) !important;
        color: transparent !important;
        border-radius: 100px;
        height: 46px;
        width: 290px;
        margin: 10px 0px 28px;
    }

    .description {
        color: transparent !important;
    }
}
</style>